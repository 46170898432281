import {
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useMediaQuery,
} from "@chakra-ui/react"
import * as React from "react"
import { PageProps, graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Associations from "../components/associations"
import { PointyButton, Underlined } from "../components/ui"
import { IClientStoryExcerpt } from "../components/clientStories"
import BlockHeader from "../components/blockHeader"
import { SEO } from "@avldev/gatsby-theme-core-ui/tools"

interface PageImage {
  alt: string
  image: IGatsbyImageData
}

interface DataProps {
  hero: PageImage
  leftImg: PageImage
  rightImg: PageImage
  story?: {
    nodes: IClientStoryExcerpt
  }
}

const BG_VIDEO_URL = `https://sundoghomes.s3.amazonaws.com/videos/hero-4-1080.mp4`

const IndexPage: React.FC<PageProps<DataProps>> = ({
  data: { story },
  location,
}): JSX.Element => {
  gsap.registerPlugin(ScrollTrigger)

  const [isLargerThan650] = useMediaQuery("(min-width: 650px)")
  const [videoWidth, setVideoWidth] = React.useState<number>(500)

  const weWorkRef = React.useRef()

  React.useEffect(() => {
    const declareVideoWidth = () => {
      if (typeof window !== `undefined`) {
        setVideoWidth(window.innerWidth)
      }
    }
    declareVideoWidth()

    gsap.to(weWorkRef?.current, {
      backgroundSize: `100% 100%`,
      delay: 2,
      duration: 1,
      ease: `slow`,
      scrollTrigger: weWorkRef.current,
    })

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill())
    }
  }, [])

  const featuredStory = story.nodes[0]
  return (
    <Layout showAssociations={false} showNewsletter={false}>
      <SEO
        meta={{
          description:
            "Want to build a custom home in Asheville NC or Jackson County NC? Find out why Sundog Homes is a preferred custom home builder in Western North Carolina.",
          image: `${location.origin}/porch-on-glenville.jpg`,
          imageAlt: `Lake Glenville from the porch of a Sundog-built home`,
        }}
        title="Custom Home Builder in Asheville NC | Sundog Homes"
        url={location.href}
      />
      <Hero
        content={
          <Box
            align="start"
            color="white"
            d="flex"
            flexDir="column"
            maxW="95vw"
            w={[`600px`, , `700px`, `800px`]}
          >
            <Heading
              as="h1"
              fontSize={[`5xl`, `6xl`, `7xl`, `8xl`]}
              lineHeight={0.9}
            >
              Your home, your way.
            </Heading>
            <Text fontSize={[`xl`, , `2xl`, `3xl`]} fontWeight="medium" mt={6}>
              Whether you{" "}
              <Underlined color="#FC8181">bring your own plan</Underlined> or{" "}
              <Underlined color="#FC8181">customize one of ours</Underlined>,
              Sundog Homes was designed from the ground up to make your vision{" "}
              our priority.
            </Text>
            <PointyButton
              aria-label="See Why Clients Choose Us"
              colorScheme="red"
              fontSize={[`lg`, , `xl`]}
              mt={[6, , 8, 12]}
              shadow="lg"
              size="lg"
              to="/get-started/"
              w="325px"
            >
              Let's Get Started
            </PointyButton>
          </Box>
        }
        image={
          <Box h="100%" w="100%">
            {isLargerThan650 && (
              <video
                autoPlay
                loop
                muted
                preload="auto"
                style={{ height: `100%`, objectFit: `cover`, width: `100%` }}
                width={videoWidth}
              >
                <source src={BG_VIDEO_URL} type="video/mp4" />
              </video>
            )}
            {!isLargerThan650 && (
              <StaticImage
                alt="A porch overlooking Lake Glenville"
                className="squared"
                layout="fullWidth"
                quality={25}
                src="../images/porch-on-glenville.jpg"
                style={{ height: `100%`, width: `100%` }}
              />
            )}
          </Box>
        }
      />
      <Flex
        align="center"
        bg="gray.50"
        flexDir="column"
        justify="center"
        minH={[`60vh`, , `45vh`]}
        pb={12}
        py={24}
        w="100vw"
      >
        <Box maxW="95vw" mb="100px" mx="auto" textAlign="center" w="800px">
          <Text
            fontFamily="heading"
            fontSize="5xl"
            fontWeight="bold"
            letterSpacing="tight"
            lineHeight="shorter"
            mb={2}
          >
            A plan for every plan
          </Text>
          <Text fontSize="xl">
            Because we believe your custom home builder should meet you where
            you are.
          </Text>
        </Box>
        <SimpleGrid
          columns={[1, , 2]}
          maxW="95vw"
          margin="auto"
          spacing={10}
          w="800px"
        >
          <Box>
            <BlockHeader as={Text} mb={1} textAlign="center" w="162px">
              The Designer
            </BlockHeader>
            <Text
              fontFamily="heading"
              fontSize={[`4xl`, , `3xl`]}
              fontWeight="bold"
              letterSpacing="tight"
              lineHeight="shorter"
              mb={4}
            >
              Customize a Sundog plan
            </Text>
            <Text color="gray.600" fontSize="lg" mb={6}>
              We've taken care of the tedious bits so you can get straight to
              the fun part: personalization. Whether your wish list is as simple
              as changing flooring or as ambitious as relocating the kitchen,
              modifying a Sundog plan is the perfect way to get a head start on
              your custom home.
            </Text>
            <PointyButton
              bg="white"
              colorScheme="gray"
              size="lg"
              to="/home-plans/"
              w={[`full`, `auto`]}
            >
              View Sundog Plans
            </PointyButton>
          </Box>
          <Box h="350px">
            <StaticImage
              alt="A photo of a Sundog plan's blueprint"
              objectFit="cover"
              src="../images/plan-closeup-1.jpg"
              style={{ height: `100%` }}
              width={650}
            />
          </Box>
        </SimpleGrid>
      </Flex>

      <Flex
        align="center"
        bg="white"
        justify="center"
        minH={[`60vh`, , `45vh`]}
        pb={12}
        py={24}
        w="100vw"
      >
        <SimpleGrid
          columns={[1, , 2]}
          maxW="95vw"
          mx="auto"
          spacing={10}
          w="800px"
        >
          <Box order={[1, , 2]}>
            <BlockHeader as={Text} mb={1} textAlign="center" w="156px">
              The Planner
            </BlockHeader>
            <Text
              fontFamily="heading"
              fontSize={[`4xl`, , `3xl`]}
              fontWeight="bold"
              letterSpacing="tight"
              lineHeight="shorter"
              mb={4}
            >
              Bring your own plan
            </Text>
            <Text color="gray.600" fontSize="lg" mb={6}>
              Already found the perfect plan? Using our decades of experience
              and highly specialized knowledge, we'll help you determine how to
              tailor it to the challenging landscape of the Western Carolina
              mountains — and then build it to your exact specifications.
            </Text>
            <PointyButton
              colorScheme="gray"
              size="lg"
              to="/free-homesite-evaluations/"
              w={[`full`, `auto`]}
            >
              Request a Homesite Evaluation
            </PointyButton>
          </Box>
          <Box h="350px" order={[2, , 1]}>
            <StaticImage
              alt="An aerial photo of Sundog build on Bear Lake."
              objectFit="cover"
              src="../images/bear-lake-aerial.jpg"
              style={{ height: `100%` }}
              width={650}
            />
          </Box>
        </SimpleGrid>
      </Flex>

      <Flex
        align="center"
        bg="gray.100"
        justify="center"
        minH={[`60vh`, , `45vh`]}
        py={24}
        w="100vw"
      >
        <SimpleGrid
          columns={[1, , 2]}
          maxW="95vw"
          mx="auto"
          spacing={10}
          w="800px"
        >
          <Box>
            <BlockHeader as={Text} mb={1} textAlign="center" w="159px">
              The Dreamer
            </BlockHeader>
            <Text
              fontFamily="heading"
              fontSize={[`4xl`, , `3xl`]}
              fontWeight="bold"
              letterSpacing="tight"
              lineHeight="shorter"
              mb={4}
            >
              Start from scratch
            </Text>
            <Text color="gray.600" fontSize="lg" mb={6}>
              A truly custom home build starts with the search for a truly
              perfect partner. Partnering with Sundog means partnering with
              subject matter experts who relentlessly pursue your vision, but
              never try to define it.
            </Text>
            <PointyButton
              bg="white"
              colorScheme="gray"
              size="lg"
              to="/client-stories/"
              w={[`full`, `auto`]}
            >
              Read Client Stories
            </PointyButton>
          </Box>
          <Box h="350px">
            <StaticImage
              alt="The interior of a truly custom Sundog build."
              objectFit="cover"
              src="../images/custom-build.jpg"
              style={{ height: `100%` }}
              width={650}
            />
          </Box>
        </SimpleGrid>
      </Flex>

      {story && (
        <Box
          bgGradient="linear(to-b, white 60%, red.600 60%, pink.800)"
          px={{ base: 6, sm: 12, md: 12 }}
          pt={24}
        >
          <Box maxW="800px" mx="auto" pb={12} textAlign="center" w="100%">
            <Heading as="h4" fontSize="4xl" fontWeight="bold">
              Featured Client Story
            </Heading>
            <Box bg="red.500" h="6px" mt={2} mx="auto" rounded="md" w="50px" />
          </Box>
          <Box
            d="flex"
            h={{ base: `35vh`, md: `50vh` }}
            justifyContent="center"
            maxH="40vh"
            maxW="800px"
            w="100%"
            px={0}
            py={0}
            mx="auto"
            my={0}
            rounded="lg"
            shadow="lg"
          >
            <Image
              alt={featuredStory.featuredImage.description}
              as={GatsbyImage}
              className="squared"
              h="100%"
              image={featuredStory.featuredImage.gatsbyImageData}
              roundedTop="lg"
              w="100%"
            />
          </Box>
          <Box
            roundedBottom="lg"
            bg="white"
            maxW="800px"
            mt={-1}
            mx="auto"
            px={[4, 6, 10, 16]}
            py={[2, 4, 6]}
            pos="relative"
            shadow="md"
            style={{ zIndex: 1 }}
          >
            <Box fontSize={{ base: `2xl`, md: `3xl` }} fontWeight="bold">
              {featuredStory.storyTitle}
            </Box>
            <Text mb={6} mt={4}>
              {featuredStory.meetTheClient.childMarkdownRemark.excerpt}
            </Text>
            <PointyButton
              aria-label="Read Their Story"
              colorScheme="red"
              mb={2}
              shadow="md"
              size="lg"
              to={`/client-stories/${featuredStory.slug}/`}
            >
              Read Their Story
            </PointyButton>
          </Box>
        </Box>
      )}
      <Box
        bgGradient="linear(to-b, pink.800, purple.900, gray.800)"
        mx="auto"
        pos="relative"
        px={{ base: 6, sm: 12, md: 0 }}
        py={{ base: 8, sm: 12, md: 16 }}
        w="100vw"
      >
        <Flex
          align="center"
          color="white"
          direction="column"
          justify="center"
          maxW="740px"
          mt={24}
          mx="auto"
        >
          <Heading as="h5" size="2xl">
            Sundog says:{" "}
            <Underlined
              color="var(--chakra-colors-red-500)"
              ref={weWorkRef}
              style={{
                backgroundSize: `0% 100%`,
                backgroundRepeat: `no-repeat`,
              }}
            >
              We work for you
            </Underlined>
            .
          </Heading>
          <Text fontSize="lg" maxW="740px" mt={12}>
            Buying a custom home means placing profound trust in a builder.
            That's why we follow a strict policy of absolute transparency: our
            clients are always welcome on their jobsites; questions are
            encouraged; and progress reports are delivered ad nauseam. Want to
            drop in from afar? No problem — your project manager will take you
            on a virtual walk-through.
          </Text>
          <PointyButton
            aria-label="Schedule a consultation"
            colorScheme="red"
            mt={8}
            shadow="lg"
            size="lg"
            to="/schedule-consultation/"
          >
            Schedule a Consultation
          </PointyButton>
        </Flex>
      </Box>
      <Box
        bgGradient="linear(to-b, gray.800, gray.800)"
        h={{ base: `70vw`, sm: `350px`, md: `375px` }}
        pos="relative"
        pt={12}
        w="100vw"
      >
        <StaticImage
          alt="The Sundog Homes team"
          transformOptions={{
            fit: `contain`,
            duotone: {
              highlight: `#FED7D7`,
              shadow: `#1A202C`,
            },
          }}
          src="../images/sundog-team-2023.png"
          layout="fullWidth"
          style={{
            filter: `contrast(1.2)`,
            height: `100%`,
            margin: `auto`,
            maxWidth: 600,
            width: `100vw`,
          }}
        />
        <Box
          bgGradient="linear(to-b, transparent 50%, gray.900 95%)"
          h="100%"
          left="0"
          pos="absolute"
          top="0"
          w="100%"
        />
      </Box>
      <Box bg="gray.900" w="100vw">
        <Associations iconTheme="light" />
      </Box>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "Sundog Realty - Search Hero" }) {
      alt: description
      image: gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
    story: allContentfulClientStory(
      filter: { showOnHomepage: { eq: true } }
      limit: 1
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        ...ClientStoryExcerpt
      }
    }
    leftImg: contentfulAsset(title: { eq: "Bear Lake House" }) {
      alt: description
      image: gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
    }
    rightImg: contentfulAsset(title: { eq: "Realty - testimonial3" }) {
      alt: description
      image: gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
    }
  }
`
